import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { ComponentProps } from 'lib/component-props';

export type StandardManualCardProps = ComponentProps & {
  fields: {
    title: Field<string>;
    categorySubheading: Field<string>;
    description: Field<string>;
    link: LinkField;
    noFollow?: Field<boolean>;
  };
  editorActive: boolean;
  hasCategorySubheader: boolean;
  headerComponent: JSX.Element | null;
};

const StandardManualCard = (props: StandardManualCardProps): JSX.Element => {
  const { editorActive, hasCategorySubheader } = props;
  return (
    <div className="w-full flex flex-col shadow-card rounded bg-white border border-1 border-base-light overflow-hidden">
      {props.headerComponent}

      <div className="p-8 flex flex-1 justify-center flex-col">
        {(hasCategorySubheader || editorActive) && (
          <div className="uppercase accent-2 text-secondary tracking-[0.2rem] mb-4">
            <RichText field={props.fields?.categorySubheading} className="rte" />
          </div>
        )}

        <div className="intro-text-bold mb-4">
          <RichText field={props.fields?.title} className="rte" />
        </div>
        <div className="mb-4 flex-1">
          <RichText field={props.fields?.description} className="rte" />
        </div>
        <div>
          <NextLink
            field={props.fields?.link}
            linkCustomizations={props.fields}
            className="btn-link p-0 hover:bg-transparent focus:bg-transparent focus-visible:pr-4"
            hideText
          >
            <span>{props.fields?.link?.value?.text}</span>
            <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
          </NextLink>
        </div>
      </div>
    </div>
  );
};

export default StandardManualCard;
